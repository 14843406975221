import { storageReportAvaliable, timeout } from '.'
import Toast from '../../plugins/toast'

export const createMutiplesReport = async thisObject => {
  if (!storageReportAvaliable()) {
    thisObject.cancelPrint()
    return
  }

  try {
    localStorage.setItem('reportAvaliable', true)
    thisObject.dialog = true
    thisObject.loadPercentage = 0

    const load = 100 / thisObject.reports.length

    for (const element of thisObject.reports) {
      if (!thisObject.dialog) break

      thisObject.codRelatorio = element.CodRelatorio

      await timeout(3000)

      if (thisObject.$refs.exportExternReport) {
        thisObject.$refs.exportExternReport.exportRel().then(() => {
          thisObject.loadPercentage += load
        })
      }
    }

    await timeout(3000)

    thisObject.relatorios.forEach(item => {
      item.checked = false
    })

    thisObject.reports = []
    thisObject.dialog = false
  } catch (err) {
    Toast().fire({
      icon: 'error',
      title: 'Não foi possivel gerar o relatório!',
    })
  } finally {
    localStorage.removeItem('reportAvaliable')
    thisObject.reports = []
    thisObject.dialog = false
    thisObject.cancelPrint()
  }
}
