/* eslint-disable */
import moment from 'moment'
import XLSX from 'xlsx-js-style'
import reportTypeServices from '../../../../services/api/reportType'
import reportServices from '../../../../services/api/reports'

const PAGE_FIELDS = [
  {
    key: 'historicoProducaoLeiteiraForm',
    label: 'Histórico de produção',
    headers: ['Produtor', 'Histórico de produção leiteira', 'Ano'],
    params: ['historicoProducaoLeiteira', 'periodoProducaoLeiteiraInicial'],
  },
  {
    key: 'producaoLeiteiraMediaRebanhoForm',
    label: 'Produção leiteira média',
    headers: ['Produtor', 'Produção leiteira média por rebanho', 'Ano'],
    params: ['producaoLeiteiraMediaRebanho', 'producaoLeiteiraInicial'],
  },
  {
    key: 'historicoValorRecebidoLitroLeiteForm',
    label: 'Histórico de valor por litro',
    headers: ['Produtor', 'Histórico de valor por litro', 'Ano'],
    params: [
      'historicoValorRecebidoLitroLeite',
      'historicoValorRecebidoLitroLeitePeriodoInicial',
    ],
  },
]

export const createSheet = async ({ reports, filename }) => {
  if (!reports || reports.length === 0) return

  const structure = await reportTypeServices.getStructure({ id: 15 })
  const data = structure.data.steps
  const fields = getFields(data).filter(
    item => !EXCLUDED_FIELDS.includes(item.name),
  )

  const ids = reports.map(report => report.CodRelatorio)

  const reportPromises = await reportServices.getReports({ ids })

  let resolvedReports = reportPromises.data.sort((a, b) => {
    const dataA = moment(
      a.find(item => item.key === 'dataAtendimento').value,
      'DD/MM/YYYY',
    )
    const dataB = moment(
      b.find(item => item.key === 'dataAtendimento').value,
      'DD/MM/YYYY',
    )

    return dataA.isBefore(dataB) ? 1 : -1
  })

  fields.splice(1, 0, {
    name: 'produtor',
    label: 'Produtor',
    componentType: 'input',
  })

  const wb = XLSX.utils.book_new()
  const headers = fields.map(field => field.label)
  const values = resolvedReports.map(report =>
    fields.map(field => getFieldValue(field, report, data)),
  )

  const ws = XLSX.utils.aoa_to_sheet([headers, ...values])
  styleHeaders(ws, headers)
  XLSX.utils.book_append_sheet(wb, ws, 'Relatórios')

  PAGE_FIELDS.forEach(field => {
    const tableValues = getTableValues(resolvedReports, field)
    const ws = XLSX.utils.aoa_to_sheet([field.headers, ...tableValues])
    styleHeaders(ws, field.headers)
    XLSX.utils.book_append_sheet(wb, ws, field.label)
  })

  const datetime = moment().format('DD-MM-YYYY')
  XLSX.writeFile(wb, `${filename}-${datetime}.xlsx`)
}

const EXCLUDED_FIELDS = [
  'assinaturaResponsavel',
  'assinaturaProdutor',
  'assinaturaProdutorOuResponsavel',
  'assinaturaConsultor',
  'historicoProducaoLeiteiraForm',
  'producaoLeiteiraMediaRebanhoForm',
  'historicoValorRecebidoLitroLeiteForm',
]

const getFields = data => {
  let fields = []
  data.forEach(item => {
    if (item.componentType == 'buttonSelect' && !item.fields) return

    if (item.type === 'form') {
      fields = fields.concat(getFields(item.fields))
    } else if (item.componentType === 'multiSelectionGroup') {
      item.options.forEach(option => {
        option['name'] = option.value
        option['option'] = item.name
        fields.push(option)
      })
    } else if (item.componentType !== 'multiInsertForm') {
      fields.push(item)
      if (item.fields) fields = fields.concat(getFields(item.fields))
      if (item.fields1) fields = fields.concat(getFields(item.fields1))
    }
  })
  return fields
}

const getFieldValue = (field, report, structure) => {
  const parent = findImmediateParentByNameInArray(structure, field.name)

  if (
    parent?.componentType === 'buttonSelect' &&
    getValue(parent?.name, report) !== '1'
  ) {
    return
  }

  if (field.componentType === 'buttonSelect' && field.option) {
    const value = getValue(field.option, report)
    return value && value.includes(field.value) ? 'Sim' : 'Não'
  }
  if (field.componentType === 'selection') {
    const findField = field.fields.find(f => getValue(f.name, report))
    return findField ? findField.label : ''
  }
  if (field.componentType === 'buttonSelect' && !field.fields) {
    return
  }
  const find = report.find(item => item.key === field.name)
  return find ? find.value : ''
}

const getValue = (key, report) => {
  const find = report.find(item => item.key === key)
  return find ? find.value : ''
}

const getTableValues = (reports, field) => {
  return reports.flatMap(report => {
    const produtor = getValue('produtor', report)
    const pageField = JSON.parse(
      report.find(item => item.key === field.key)?.value || '[]',
    )
    return pageField.map(item => [
      produtor,
      item[field.params[0]],
      item[field.params[1]],
    ])
  })
}

const styleHeaders = (ws, headers) => {
  headers.forEach((item, index) => {
    const cell = XLSX.utils.encode_cell({ c: index, r: 0 })
    ws[cell].s = {
      font: { bold: true, color: { rgb: 'ffffff' }, sz: 14 },
      fill: { fgColor: { rgb: '01579b' } },
    }
    ws['!cols'] = ws['!cols'] || []
    ws['!cols'].push({ wch: item === 'Produtor' ? 50 : item.length + 10 })
  })
}

function findImmediateParentByNameInArray(structureArray, name) {
  for (let structure of structureArray) {
    let result = findImmediateParentByName(structure, name)
    if (result) {
      return result
    }
  }
  return null
}

function findImmediateParentByName(structure, name) {
  if (structure.fields && structure.fields.length > 0) {
    for (let field of structure.fields) {
      if (field.name === name) {
        return structure
      }

      let result = findImmediateParentByName(field, name)
      if (result) {
        return result
      }
    }
  }

  return null
}
