import axios from 'axios'
import { ENV_APP } from '../../../../env'
import api from '../../../api/api'

export default {
  list: async () => {
    const result = await api.get.listReportsType()

    return result.data
  },

  getStructure({ id }) {
    const projectID = ENV_APP.ProjectID

    return axios.get(`/projectstructure/${projectID}/structure/${id}`)
  },
}
