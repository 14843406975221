<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-data-table
        :items="statusData"
        :headers="headers"
        :item-class="itemRowBackground"
      >
        <template v-slot:[`item.Data`]="{ item }">
          {{ moment(item.Data).format('DD/MM/YYYY HH:mm:ss') }}
        </template>
        <template v-slot:[`item.Status`]="{ item }">
          {{ typeValidation(item.Status) }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="
                    $user.get().role === 'admin' ||
                    $user.get().role === 'gestor' ||
                    (statusData.length > 0 &&
                      statusData[0].Status == 2 &&
                      $user.get().role === 'consultor')
                  "
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ formTitle }}
                </v-btn>
              </template>
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-toolbar color="primary" dark>
                <v-col cols="12">
                  <span style="font-size: 18px"> Exclusão de Avaliação </span>
                  <br />
                </v-col>
              </v-toolbar>
              <v-card>
                <v-card-title class="text-h4"
                  >Você tem certeza que deseja excluir esse item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="success"
                    :loading="salvando"
                    @click="deleteItemConfirm"
                    >Sim</v-btn
                  >
                  <v-btn color="error" :disabled="salvando" @click="closeDelete"
                    >Não</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-toolbar color="primary" dark>
                  <v-col cols="12">
                    <span style="font-size: 20px"> {{ formTitle }} </span>
                  </v-col>
                </v-toolbar>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        v-if="
                          $user.get().role === 'admin' ||
                          $user.get().role === 'gestor'
                        "
                        cols="12"
                        md="12"
                        style="padding: 0px"
                      >
                        <v-select
                          v-model="lancamento.tipo"
                          :items="tipos"
                          label="Status da Avaliação"
                          :rules="requiredRules"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        style="padding-top: 0px; padding-bottom: 0px"
                      >
                        <v-textarea
                          v-model="lancamento.descricao"
                          filled
                          label="Descrição da Atualização"
                          :rules="requiredRules"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    color="success"
                    width="100"
                    :loading="salvando"
                    @click="save"
                  >
                    <v-icon dark left> mdi-checkbox-marked-circle </v-icon>
                    Salvar
                  </v-btn>
                  <v-btn
                    color="error"
                    width="120"
                    :disabled="salvando"
                    @click="close"
                  >
                    <v-icon dark left> mdi-minus-circle </v-icon>
                    Cancelar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template
          v-if="
            this.$user.get().role === 'admin' ||
            this.$user.get().role === 'gestor'
          "
          v-slot:[`item.actions`]="{ item }"
        >
          <v-icon color="red darken-3" small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <span style="font-size: 15px">
            Relatório sem atualizações até o momento.
          </span>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {},

  props: {
    relatorio: {
      type: Array,
      default: () => [],
    },
    codRelatorio: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      loading: true,
      salvando: false,
      statusData: [],
      dialog: false,
      dialogDelete: false,
      lancamento: {
        id: '',
        descricao: null,
        tipo: null,
      },
      idItem: null,
      requiredRules: [v => !!v || '* Obrigatório'],
      tipos: [
        { text: 'Válido', value: 1 },
        { text: 'Inconsistente', value: 2 },
      ],
      headers: [
        {
          text: 'Realizada por',
          value: 'UserName',
          align: 'center',
          class: 'primary white--text title',
          width: '200',
        },
        {
          text: 'Status',
          value: 'Status',
          align: 'center',
          class: 'primary white--text title',
          width: '100',
        },
        {
          text: 'Mensagem',
          value: 'Descricao',
          align: 'left',
          class: 'primary white--text title',
          width: '600',
        },
        {
          text: 'Data',
          value: 'Data',
          align: 'center',
          class: 'primary white--text title',
          width: '50',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          class: 'primary white--text title',
          width: '25',
        },
      ],
    }
  },
  computed: {
    formTitle() {
      if (
        (this.statusData.length > 0 && this.statusData[0].Status == 0) ||
        (this.statusData.length > 0 && this.statusData[0].Status == 3)
      )
        return 'Nova Avaliação'
      if (
        this.statusData.length > 0 &&
        this.statusData[0].Status == 2 &&
        this.$user.get().role === 'consultor'
      )
        return 'Justificativa'
      return 'Nova Avaliação'
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  mounted() {
    if (!this.codRelatorio) {
      this.$router.push({
        path: `/${this.$user.get().role}/relatorios/geral`,
      })
    } else {
      this.getReportValidation()
    }
  },

  methods: {
    itemRowBackground: function (item) {
      return item.Status == 1 ? 'style-1' : 'style-2'
    },
    getReportValidation() {
      this.loading = true
      this.api.get.relatoriovalidacao(this.codRelatorio).then(data => {
        this.statusData = data
        this.loading = false
      })
    },

    close() {
      this.dialog = false
    },

    closeDelete() {
      this.dialogDelete = false
    },

    save() {
      if (
        this.statusData.length > 0 &&
        this.statusData[0].Status == 2 &&
        this.$user.get().role === 'consultor'
      ) {
        this.valid = this.validateConsultor()
      } else {
        this.valid = this.validate()
      }

      if (!this.valid) {
        this.Swal.fire(
          'Atenção',
          'É necessário preencher todos os itens',
          'warning',
        )
        return false
      } else {
        this.lancamento.id = this.codRelatorio
        this.salvando = true
        this.api.save
          .relatoriovalidacao(this.lancamento)
          .then(response => {
            this.close()
            this.Swal.fire({
              title: 'Sucesso',
              text: response,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              this.getReportValidation()
              this.$root.$emit('updateGrid')
              this.salvando = false
            })
          })
          .catch(() => {
            this.getReportValidation()

            this.salvando = false
            this.close()
          })
      }
    },

    validate() {
      return !!(this.lancamento.descricao && this.lancamento.tipo)
    },

    validateConsultor() {
      return !!this.lancamento.descricao
    },

    typeValidation(status) {
      if (status == 0) return 'Não validado'
      else if (status == 1) return 'Válido'
      else if (status == 2) return 'Inconsistente'
      else if (status == 3) return 'Justificado'
      return '-'
    },

    tipoVerificacion(tipo) {
      if (!tipo) return 3
      else {
        if (this.statusData.length > 0 && this.statusData[0].Status == 3)
          return 4
        else return tipo
      }
    },

    deleteItem(item) {
      this.idItem = item.Id
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.salvando = true
      this.api.delete.relatoriovalidacao(this.idItem).then(
        () => {
          this.getReportValidation()
          this.$root.$emit('updateGrid')
          this.salvando = false
          this.closeDelete()
          this.Swal.fire({
            title: 'Sucesso',
            text: 'Item excluído com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
          })

          return 1
        },
        reject => {
          return reject
        },
      )
    },
  },
}
</script>
<style>
.style-1 {
  background-color: rgba(48, 211, 15, 0.534);
}
.style-2 {
  background-color: rgb(255, 255, 255);
}
</style>
